import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/global/layout';
import Container from '../components/global/container';
import Header from '../components/global/header';
import SEO from '../components/global/seo';
import Button from '../components/global/elements/buttons/button';
import PortableText from '../components/portableText';
import { buildImageObj } from '../lib/helpers';
import { imageUrlFor } from '../lib/image-url';
import AnimatedImage from '../components/global/animated-image';
import AnimatedText from '../components/global/animated-text';
import AnimatedVideo from '../components/global/animated-video';
import AvivaLogo from '../static/aviva-logo.svg';
import PlayButton from '../static/play--button.svg';
import EmailIcon from '../static/email.svg';

export const query = graphql`
  query AvivaPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      media {
        video {
          asset {
            url
          }
        }
      }
    }
    static: sanityAviva(slug: { current: { eq: "aviva" } }) {
      seo {
        title
        keywords
        description
      }
      bannerMedia {
        image {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        video {
          asset {
            url
          }
        }
        alt
      }
      nhsMedia {
        image {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        video {
          asset {
            url
          }
        }
        alt
      }
      _rawIntro
      _rawContent(resolveReferences: { maxDepth: 10 })
      _rawNhs
      _rawNhsContent(resolveReferences: { maxDepth: 10 })
      teamImage {
        image {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        video {
          asset {
            url
          }
        }
        alt
      }
    }
  }
`;

const AvivaLandingPage = (props) => {
    const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const content = (data || {}).static;

  const avivaVideo = useRef();
  const playButton = useRef();
  const nhsVideo = useRef();

  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayingNhs, setIsPlayingNhs] = useState(false);

  useEffect(() => {
    document.querySelectorAll('.case-anchor').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
          e.preventDefault();
  
          document.querySelector(this.getAttribute('href')).scrollIntoView({
              behavior: 'smooth'
          });
      });
    });
  });

  const handlePlay = e => {
    if(!isPlaying) {
      setIsPlaying(true);
      avivaVideo.current.play();
    } else {
      setIsPlaying(false);
      avivaVideo.current.pause();
    }
  }

  const handlePlayNhs = e => {
    if(!isPlayingNhs) {
      setIsPlayingNhs(true);
      nhsVideo.current.play();
    } else {
      setIsPlayingNhs(false);
      nhsVideo.current.pause();
    }
  }

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }
  
  return (
    <>
        <Header headerStyle="clear" />
        <Layout>
        {content.seo && (
          <SEO
            title={content.seo.title !== null ? content.seo.title : false}
            description={content.seo.description !== null ? content.seo.description : false}
            keywords={content.seo.keywords !== null ? content.seo.keywords : false}
            image={content.seo.image !== null ? content.seo.image : false}
          />
        )}
        <Container>
            <div className='site-wide'>
              <div className='aviva--page-banner'>
                <div className='aviva--page-banner-copy'>
                    <div className='copy'>
                      {content._rawIntro && <PortableText blocks={content._rawIntro} />}
                    </div>
                    <div className='aviva--logo'>
                      <img src={AvivaLogo} alt="Aviva" />
                    </div>
                </div>
              </div>
            </div>

            <div className='aviva--page-banner-image'>
              <div className='site-wide'>
                <div className='video--wrapper'>
                  {isPlaying ? null :
                  <div className='video--overlay' onClick={handlePlay}>
                     <img src={PlayButton} ref={playButton} />
                  </div>
                  }
                  {content.bannerMedia.video && content.bannerMedia.video.asset && (
                    <video
                      ref={avivaVideo}
                      className="hero__media"
                      poster={imageUrlFor(buildImageObj(content.bannerMedia.image)).url()}
                      controls="false"
                    >
                      <source src={content.bannerMedia.video.asset.url} type="video/mp4" />
                    </video>
                  )}
                </div>
              </div>
            </div>

            <div className='site-wide'> 
              <div className='aviva--page-work' id="caseStudies">
                  {content._rawContent &&
                      content._rawContent.map((rC, index) => {
                          return(
                              <div className='aviva--page-work-flex' key={index}>
                                  <div className='aviva--page-work-image'>
                                      {rC.column1 &&
                                          rC.column1.map((c1, index) => {
                                              if (c1.video) {
                                                return (
                                                  <a href={rC.button}>
                                                    <AnimatedVideo key={index} src={c1.video.asset.url} />
                                                  </a>
                                                );
                                              } else if (c1.image) {
                                              return (
                                                <a href={rC.button}>
                                                  <AnimatedImage
                                                  key={index}
                                                  src={imageUrlFor(buildImageObj(c1.image))}
                                                  alt={c1.alt}
                                                  />
                                                  </a>
                                              );
                                              }
                                      })}
                                  </div>
                                  <div className='aviva--page-work-copy'>
                                    {rC.column2 &&
                                        rC.column2.map((c2, index) => <AnimatedText blocks={c2} key={index} />)}
                                    {rC.buttonCopy && <Button text={rC.buttonCopy} link={rC.button} />}
                                  </div>
                              </div>
                          )
                  })}
              </div>

              <div className='aviva--cta'>
                <div className='aviva--cta-wrapper'>
                  <div className='aviva--cta-copy'>
                    <strong>Like what you see?</strong> I'd love to have a chat with you and see if there's any value we can add to your next project.
                  </div>
                  <a href="mailto:vicki.nahajec@ewe.agency" className='aviva--cta-email'>
                    <img src={EmailIcon} />
                  </a>
                </div>
                <div
                  className='aviva--cta-image'
                  style={{
                    backgroundImage: `url(${imageUrlFor(buildImageObj(content.teamImage.image)).url()})`
                  }}
                ></div>
                
              </div>

            </div>

            <div className='site-wide'>
              <div className='aviva--page-banner -nhs'>
                <div className='aviva--page-banner-copy'>
                    <div className='copy'>
                      {content._rawNhs && <AnimatedText blocks={content._rawNhs} />}
                    </div>
                </div>
              </div>
            </div>

            <div className='aviva--page-banner-image'>
              <div className='site-wide'>
                <div className='video--wrapper'>
                  {isPlayingNhs ? null :
                  <div className='video--overlay' onClick={handlePlayNhs}>
                     <img src={PlayButton} ref={playButton} />
                  </div>
                  }
                  {content.nhsMedia.video && content.nhsMedia.video.asset && (
                    <video
                      ref={nhsVideo}
                      className="hero__media"
                      poster={imageUrlFor(buildImageObj(content.nhsMedia.image)).url()}
                      controls="false"
                    >
                      <source src={content.nhsMedia.video.asset.url} type="video/mp4" />
                    </video>
                  )}
                </div>
              </div>
            </div>

            <div className='site-wide'> 
              <div className='aviva--page-work'>
                  {content._rawNhsContent &&
                      content._rawNhsContent.map((rC, index) => {
                          return(
                              <div className='aviva--page-work-flex' key={index}>
                                  <div className='aviva--page-work-image'>
                                      {rC.column1 &&
                                          rC.column1.map((c1, index) => {
                                              if (c1.video) {
                                                return (
                                                  <a href={rC.button}>
                                                    <AnimatedVideo key={index} src={c1.video.asset.url} />
                                                  </a>
                                                );
                                              } else if (c1.image) {
                                              return (
                                                <a href={rC.button}>
                                                  <AnimatedImage
                                                  key={index}
                                                  src={imageUrlFor(buildImageObj(c1.image))}
                                                  alt={c1.alt}
                                                  />
                                                  </a>
                                              );
                                              }
                                      })}
                                  </div>
                                  <div className='aviva--page-work-copy'>
                                    {rC.column2 &&
                                        rC.column2.map((c2, index) => <AnimatedText blocks={c2} key={index} />)}
                                    {rC.buttonCopy && <Button text={rC.buttonCopy} link={rC.button} />}
                                  </div>
                              </div>
                          )
                  })}
              </div>

              <div className='aviva--cta -bottom'>
                <div className='aviva--cta-wrapper'>
                  <div className='aviva--cta-copy'>
                    <strong>Like what you see?</strong> I'd love to have a chat with you and see if there's any value we can add to your next project.
                  </div>
                  <a href="mailto:vicki.nahajec@ewe.agency" className='aviva--cta-email'>
                    <img src={EmailIcon} />
                  </a>
                </div>
                <div
                  className='aviva--cta-image'
                  style={{
                    backgroundImage: `url(${imageUrlFor(buildImageObj(content.teamImage.image)).url()})`
                  }}
                ></div>
                
              </div>

            </div>
        </Container>
        </Layout>
    </>
  )
};

AvivaLandingPage.propTypes = {
    data: PropTypes.object,
    errors: PropTypes.object
  };

export default AvivaLandingPage;
